@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');
.border-left-right{
    border: none;
    border-left: 0.1px solid rgba(128, 128, 128, 0.178);
    border-right: 0.1px solid rgba(128, 128, 128, 0.178);
    height: 100vh;
    overflow-y: auto;
    margin: 0;
    padding: 0;
}
.border-left-right::-webkit-scrollbar {
    width: 5px;
  }

.border-left-right::-webkit-scrollbar-track {
    background: rgba(36, 101, 131, 0.548);
    border-radius: 10px;
}

.border-left-right::-webkit-scrollbar-thumb {
    background:  linear-gradient(0deg, rgba(255, 0, 0, 1) 0%, rgba(7, 0, 211, 1) 100%);
    height: 0.3rem;
    border-radius: 10px;

  }

.border-left-right::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
  }

.border-left-right::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
    border-radius: 10px;
  }

.search-bar{
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid rgba(90, 90, 90, 0.589);
    padding: 10px;
    width: 20rem;
}

#none{
    display: none;
}

.border-text{
    border: none;
    background-color: transparent;
    outline: none;
}
.navigation-list{
    list-style-type: none;
}
.navigation-list-li{
    align-self:center;
    color: rgb(155, 152, 152);
    display: flex;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Libre Baskerville', serif;
    list-style-type: none;
    justify-content: space-around;
    margin: 20px 0;
}
.navigation-list-li:hover{
   color: aqua;
   transition: 1s ease;
}
.list-name{
    width: 150px;
}

.button-p1{
    justify-content: center;
    width: 14rem;
    background-color: blueviolet;
    outline: none;
    border: none;
    border-radius: 20px;
    padding: 5px;
    align-items: center;
    color: white;
}
.quotes-logo{
    width: 100px;
}

.profile-pic{
    width: 100px;
    height: 100px;
    border: 50%;
}