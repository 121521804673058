
.who-to-follow{
    width: 330px;
    height: 300px;
    overflow-y: auto;
    background-color:transparent;
    border: 1px solid rgba(0, 255, 76, 0.37);
    border-radius: 20px;
}

.follow-who{
    width: 330px;
    height: 270px;
    overflow-y: auto;
    background-color:transparent;
    border: 1px solid rgba(0, 255, 76, 0.37);
    border-radius: 20px;
}


.who-to-follow::-webkit-scrollbar {
    width: 5px;
  }

.who-to-follow::-webkit-scrollbar-track {
    background: rgba(36, 101, 131, 0.548);
    border-radius: 10px;
}

.who-to-follow::-webkit-scrollbar-thumb {
    background:  linear-gradient(0deg, rgba(255, 0, 0, 1) 0%, rgba(7, 0, 211, 1) 100%);
    height: 0.3rem;
    border-radius: 10px;

  }

.who-to-follow::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10px;
  }

.who-to-follow::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
    border-radius: 10px;
  }

.magnifying{
    color: rgb(68, 82, 82);
}

.follow-button{
    width: 5rem;
    background-color: black;
    color: white;
    padding: 0.2rem;
    font-weight: 900;
    outline: none;
    border: none;
    border-radius: 15px;
}

.privacy-term{
    display: flex;
    flex-wrap: wrap;

}
.privacy-term span{
    margin: 0 3px;
    cursor: pointer;
    font-size: 0.7rem;
}

.new-to-quotser{
    border-radius: 0.9rem;
    padding: 0.5rem;
    height: 14rem;
    border: 1px solid rgba(128, 128, 128, 0.404);
}

.new-to-quotser span{
    font-size:0.7rem;

}

.new-to-quotser p{
    font-size:0.7rem;

}

.login-button{
    padding: 5px;
    border-radius: 1rem;
    border: 1px solid rgba(83, 83, 83, 0.329);
    cursor: pointer;
}

.login-button:hover{
    background-color: rgba(160, 29, 221, 0.527);
    transition: 1s ease;
    color: white;
}

.login-button span{
    font-size: 0.9rem;
}

.privacy-term span:hover{
    border-bottom: 1px solid gray;

}

.privacy-term p{
    margin: 0 3px;
    font-size: 0.7rem;
}