@import url('https://fonts.googleapis.com/css2?family=Playfair:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica&display=swap');
.dark-mode {
  color: #ffffff;
  background-color: #000000;
}

.light-mode {
  color: #000000;
  background-color: #ffffff;
}

.logo-image-size{
  width: 100px;
  height: 100px;
}


.top-heading{
  position: sticky;  
  top:1px;  
  padding: 10px;  
  font-size:20px;  
  font-family: 'Geologica', sans-serif;;
  background-color: rgba(204, 209, 209, 0.459);  
}


.card-for-qod{
  width: 30rem;
  min-height: 15rem;
  overflow: hidden;
  border: 1px solid rgba(119, 115, 115, 0.459);
  border-radius: 15px;
  background-color: transparent;
}

.quote-card {
  min-height: 15rem;
  border: none;
  overflow: hidden;
  border-bottom: 1px solid rgba(128, 128, 128, 0.342);
  background-color: transparent;

}


.quote-card-download {
  min-height: 15rem;
  border: 1px solid rgb(8, 216, 206);
  border-radius: 15px;
  overflow: hidden;
  display: none;
  border-bottom: 1px solid rgba(128, 128, 128, 0.342);
  background-color: transparent;

}



.quote-card:hover{
  cursor: pointer;
}

.card-for-qod:hover{
  border: 1px solid rgb(0, 255, 106);
  cursor: pointer;
}

#show{
  display: none;
}
.qod-image{
  width: 3rem;
  height:3rem;
  border-radius: 50%;
}

.qod-image-search{
  width: 2rem;
  height:2rem;
  border-radius: 50%;
}

.text-li{
  color: #ff00bf;

}

.text-small{
  font-size: 0.9rem;
}
.quote-text-area > textarea{
  resize: none;
  outline: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.356);
  

}
textarea {
  resize: none;
}

.follow-users {
  display: flex;
  justify-content: space-between;
}
.author{
  display: flex;
  justify-content: flex-end;
}

.like-repost-share{
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.quote-tags{
  display: flex;
}

.quote{
  font-family: 'Playfair', serif;
  font-size: larger;
}

.author{
  font-family: 'Playfair', serif;

}

.quote-tags span{
  margin-right: 5px;
  font-size: 0.9rem;
}

.img-name{
  display: flex;
  justify-content: space-between;
}

/* .moreicon:hover{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #4d324471;
  transition: 0.1s ease;
} */

.repeate-icon:hover{
  color: #ff00bf;
  transition: 0.3s ease;
}

.bookmark-icon:hover{
  color: greenyellow;
  transition: 0.3s ease;
}

.download-icon:hover{
  color: rgb(133, 1, 1);
  transition: 0.3s ease;

}

.share-icon:hover{
  color: green;
  transition: 0.3s ease;

}

.heart-icon:hover{
  color: rgb(255, 6, 193);
  transition: 0.3s ease;
}

.heart-icon{
  color: rgb(54, 54, 54);
}

.bookmarks-data{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bookmark-card{
  width: 15rem;
  min-height: 13rem;
  border: 1px solid rgba(0, 128, 0, 0.459);
  margin: 1rem;
  border-radius: 13px;
  cursor: pointer;
}

.bookmark-card:hover{
  border: 1px solid rgba(16, 223, 16, 0.966);

}

.profile-bookmark{
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.small-profile-pic{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.bookmark-small:hover{
  color: #ff00bf;

}

.small-profile-name{
  font-size: 0.7rem;
}

.small-quote{
  font-size: 0.7rem;
}

.cards-Login{
  width: 30rem;
  height: 15rem;
  border: 1px solid rgba(4, 47, 97, 0.514);
  border-radius: 10px;
}

.cards-Login:hover{
  border: 1px solid rgba(21, 223, 112, 0.904);

}

.cards-register{

  width: 30rem;
  height: 20rem;
  border: 1px solid rgba(4, 47, 97, 0.514);
  border-radius: 10px;

}

.small-text{
  font-size: 12px;
}

.quote-tagss{
  display: flex;
  width: 30rem;
  height: 15rem;
  flex-wrap: wrap;
}

.quote-tags-span{
  max-width: 10rem;
  height: 3rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 10px;
  padding: 8px;
  margin: 10px;
  background-color: rgba(0, 255, 255, 0.575);
  color: rgb(59, 19, 39);
}



.quote-tags-span:hover{
  border: 1px solid rgba(0, 128, 0, 0.582);
  background-color: rgb(41, 94, 238);
  color: rgb(236, 230, 233);
}

.quote-tags-span::before{
  content: "#";
}