.shift-profileinfo{
    padding: 1rem;

}
.profile-pic{
    position: absolute;
    top: 200px;
}

.profile-pic{
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.profile-info{
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
}

.social-links{
    display: flex;
    list-style-type: none;
    justify-content: flex-start;
    cursor: pointer;
}
.social-links span{
    margin-right: 0.5rem;
}

.promote-addprofile{
    display: flex;
    justify-content: space-around;
}

.promote{
    width: 10rem;
    outline: none;
    padding: 0.5rem;
    border: none;
    background-color: rgb(141, 126, 105);
    border-radius: 10px;
}

.promote:hover{
    background-color: black;
    color: white;
    cursor: not-allowed;
    border: 1px solid green;

}
.create{
    width: 10rem;
    outline: none;
    padding: 0.5rem;
    border: none;
    background-color: rgb(141, 126, 105);
    border-radius: 10px;
}

.create:hover{
    background-color: black;
    color: white;
    border: 1px solid green;
}